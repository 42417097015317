import React, { useEffect, useState } from "react"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import useFetch from "../../../hooks/useFetch"
import { toast } from "react-toastify"
import { useAuth } from "../../../auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import Input from "../../../templates/EditSpace/fields/Input"

const EditHub = ({ id }) => {
  const { progress, data } = useFetch(`meetup/${id}`, "get")

  const { makeRequest } = useAuth() || {}

  const [inProgress, setProgress] = useState(false)

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [host, setHost] = useState(null)
  const [hostOrganization, setHostOrganization] = useState(null)
  const [type, setType] = useState(null)
  const [invitationCode, setInvitationCode] = useState(null)

  useEffect(() => {
    if (!data?.hub?.id) return
    setTitle(data?.hub?.title)
    setDescription(data?.hub?.description)
    setHostOrganization(data?.hub?.description)
    setHost(data?.hub?.description)
    setType(data?.hub?.type)
    setInvitationCode(data?.hub?.invitationCode)
  }, [data?.hub])

  const handleSubmit = async () => {
    setProgress(true)

    const body = {
      id: data?.hub?.id,
      values: {
        title: title?.trim(),
        description,
        type,
        hostOrganization,
        invitationCode,
        host,
      },
    }

    await makeRequest("meetup", body)

    toast.success("Hub updated successfully!")

    setProgress(false)
  }

  return (
    <>
      <SEO title="Update Hub" />
      <Layout title={"Update Hub"} progress={progress}>
        <Row>
          <Col lg="6">
            <Input
              label={"Meetup Title"}
              name="meetuptitle"
              helpText={""}
              value={title}
              onChange={(text) => setTitle(text)}
            />
            <label>Meetup Description</label>
            <textarea
              rows="10"
              className={"form-control mb-3"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Input
              label={"Host"}
              name="host"
              helpText={""}
              value={host}
              onChange={(value) => setHost(value)}
            />
            <Input
              label={"Host Organization"}
              name="hostOrganization"
              helpText={""}
              value={hostOrganization}
              onChange={(value) => setHostOrganization(value)}
            />
            <Input
              label={"Type of meetup"}
              name="type"
              helpText={""}
              value={type}
              onChange={(value) => setType(value)}
            />{" "}
            <Input
              label={"Invitation Code"}
              name="invitationCode"
              helpText={""}
              value={invitationCode}
              onChange={(value) => setInvitationCode(value)}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={4} md={3} lg={2}>
            <Button
              color={"primary"}
              size={"sm"}
              onClick={handleSubmit}
              disabled={inProgress || !title || !description}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default EditHub
