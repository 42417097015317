import { useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"

const useFetch = (endpoint = "", method = "get", payload = {}, reFetch = 0) => {
  const [progress, setProgress] = useState(true)
  const [data, setData] = useState(null)

  const { makeRequest, isAuthenticated } = useAuth() || {}

  useEffect(() => {
    if (!isAuthenticated) return
    if (!endpoint) {
      setProgress(false)
      return
    }
    setProgress(true)

    makeRequest(endpoint, payload, method)
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setProgress(false)
      })
  }, [isAuthenticated, reFetch])

  return { progress, data }
}

export default useFetch
